import Vue from 'vue'
import jwt from 'jsonwebtoken'
import {
    NOTIFICATION,
    SYMBOL_FAVORITE,
    NOTIFICATION_REMOVE,
    NOTIFICATION_ERROR,
    MODAL,
    BLUR,
    MODAL_MENU,
    USER,
    MODAL_SYMBOLS,
    GRID_LAYOUT,
    SYMBOL_SHOW,
    SYMBOL,
    SYMBOL_ACTIVE_UPDATE,
    MODAL_EXPIRATIONS,
    MODAL_FULLSCREEN,
    NOTIFICATION_ORDER,
    MUTE,
    MODAL_AMOUNTS,
    MODAL_ASSETS_INFO,
    LAST_AMOUNTS,
    LOGOUT,
    LANG,
    VARIABLE,
    CURRENCIES,
    MODAL_AUTOCLOSE,
    MODAL_PENDING,
    MODAL_CLOSE_POSITION,
    NOTIFICATION_ORDER_OPEN,
    NOTIFICATION_SUPPORT,
    TOKEN,
    NOTIFICATION_TRANSACTION, SYMBOL_HIDE, CHART, CHART_ACTIVE,
    ORDERS_PANE,
    HISTORY_TABLE_IS_ACTIVE, TIME_SHIFT, MODAL_WITHDRAWAL_CANCEL,
    SHOW_WEB_NOTIFICATION, CHECK_NOTIFICATION_PROMISE, NOTIFICATION_MARGIN, SET_TERMS_STATE, SET_THEME_COLORS
} from '@/store/mutations.type'
import {
    DROP_SESSION, FAVICON,
    LANG_DEFAULT,
    LANGUAGES,
    COMPANY_NAME,
    SYMBOLS_ACTIVE,
    VARIABLES,
    MULTIGRID, DISABLE_REGISTRATION,
    TRADINGVIEW_BG, TRADINGVIEW_LINE, SHOW_REGISTER_MODAL
} from "@/common/config"
import {
    APP_INIT, APP_READY,
    CFD_ORDER_OPEN_REQUEST,
    PLAY_CLICK,
    PLAY_ORDER_LOSE,
    PLAY_ORDER_OPEN,
    PLAY_ORDER_WIN
} from '@/store/actions.type'
import RestService from "@/common/rest.service"
import langs from "@/common/langs"
import {randomId, localStorage, isMobile, dateCorr} from '@/common/helpers'
import {BRAND} from "@/common/config";

const sounds = {
    // click: new Audio(require('@/assets/sounds/click.ogg')),
    // orderOpen: new Audio(require('@/assets/sounds/orderOpen.ogg')),
    // orderWin: new Audio(require('@/assets/sounds/orderWin.ogg')),
    // orderLose: new Audio(require('@/assets/sounds/orderLose.ogg'))
    click: new Audio(require('@/assets/sounds/click.mp3')),
    orderOpen: new Audio(require('@/assets/sounds/orderOpen.mp3')),
    orderWin: new Audio(require('@/assets/sounds/orderWin.mp3')),
    orderLose: new Audio(require('@/assets/sounds/orderLose.mp3'))
}

const state = {
    symbolsFavorite: [],
    lastAmounts: [],
    notificationId: 0,
    notifications: [],
    themeColors: {
        'background-footer': '#18202d',
        'background-dark': TRADINGVIEW_BG,
        'background-dark-mid': '#252c3b',
        'background': '#374054',
        'background-light': '#363f52',
        'white': '#ffffff',
        'font': '#8c8f96',
        'brand': '#ff7600',
        'green': '#31cf5a',
        'green-button': '#2bab40',
        'red': '#dd4931',
        'primary-button': '#ff7600',
        'secondary-button': '#424a5e',
        'border': '#353c4e',
        'line': TRADINGVIEW_LINE
    },
    modals: {
        loader: true,
        login: false,
        register: false,
        symbols: false,
        expiration: false,
        amounts: false,
        deposit: false,
        menu: true,
        sideMenu: true,
        balance: false,
        account: false,
        grids: false,
        autoClose: false,
        pending: false,
        forgotPassword: false,
        closePosition: false,
        newPassword: false,
        profitCalculator: false,
        assetsInfo: false,
        depositSuccess: false,
        depositFail: false,
        fullscreen: false,
        hamburgerMenu: false, // change on new title
        withdrawalCancel: false,
        otp: false,
        termsAndCondition: true
    },
    modalMenu: {
        orders: false,
        history: false,
        calendar: false,
        market: true,
        news: true
    },
    modalSymbols: {
        action: 'add',
        id: null,
        gridId: null
    },
    modalExpirations: {
        left: 0
    },
    modalAmounts: {
        left: 0,
        top: 0,
        amount: 10,
        volumeType: 'lots',
        contractSize: 0
    },
    modalFullscreen: {
        page: 'dashboard'
    },
    modalAutoClose: {
        left: 0,
        top: 0,
        posY: 0,
        posX: 0,
        symbolActive: null,
        orderActive: null,
        tp: 50,
        tpType: '=',
        sl: 20,
        slType: '='
    },
    modalPending: {
        left: 0,
        top: 0,
        posY: 0,
        posX: 0,
        symbolActive: null,
        price: ''
    },
    modalClosePosition: {
        activeOrderId: null
    },
    modalAssetsInfo: {
        symbol: null,
        chartActive: null,
    },
    modalWithdrawalCancel: {
        id: null
    },
    blur: true,
    mute: false,
    langActive: 'gb',
    languages: LANGUAGES,
    variables: {},
    currencies: [],
    charts: {},
    chartActive: undefined,
    gridLayout: '',
    gridCharts: {
        0: undefined,
        1: undefined,
        2: undefined,
        3: undefined
    },
    ordersPane: true,
    historyTableIsActive: false,
    timeShift: null,
    authToken: '',
    termsIsAccept: JSON.parse(localStorage.getItem('termsIsAccept')) || false
}

for (let n in VARIABLES) {
    state.variables[VARIABLES[n]] = ''
}

langs.en = langs.gb

const getters = {
    themeColors() {
        return state.themeColors
    },

    notifications() {
        return state.notifications
    },

    symbolsFavorite() {
        return state.symbolsFavorite
    },

    lastAmounts() {
        return state.lastAmounts
    },

    modals() {
        return state.modals
    },

    modalMenu() {
        return state.modalMenu
    },

    modalSymbols() {
        return state.modalSymbols
    },

    modalExpirations() {
        return state.modalExpirations
    },

    modalAmounts() {
        return state.modalAmounts
    },

    modalFullscreen() {
        return state.modalFullscreen
    },

    modalAutoClose() {
        return state.modalAutoClose
    },

    modalPending() {
        return state.modalPending
    },

    modalClosePosition() {
        return state.modalClosePosition
    },

    modalAssetsInfo() {
        return state.modalAssetsInfo
    },

    modalWithdrawalCancel() {
        return state.modalWithdrawalCancel
    },

    charts() {
        return state.charts
    },

    chartActive() {
        return state.chartActive
    },

    gridCharts() {
        return state.gridCharts
    },

    gridLayout() {
        return state.gridLayout
    },

    blur() {
        return state.blur
    },

    mute() {
        return state.mute
    },

    langActive() {
        return state.langActive
    },

    languages() {
        return state.languages
    },

    variables() {
        return state.variables
    },

    currencies() {
        return state.currencies
    },

    tokens() {
        return langs[state.langActive] || langs.gb
    },

    rawTokens() {
        return langs
    },

    ordersPane() {
        return state.ordersPane
    },

    historyTableIsActive() {
        return state.historyTableIsActive
    },

    timeShift() {
        return state.timeShift
    },

    termsIsAccept() {
        return state.termsIsAccept
    }
}

const actions = {
    [APP_INIT]() {
        this.commit(CHECK_NOTIFICATION_PROMISE)
        document.title = COMPANY_NAME

        let link = document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = FAVICON

        document.getElementsByTagName('head')[0].appendChild(link)

        let path = window.location.search.slice(1).split('&'),
            langSet = false,
            otl

        for (let n in path) {
            let t = path[n].split('='),
                param = t[0],
                val = t[1]

            switch (param) {
                case 'token':
                    state.authToken = val
                    break
                case 'hash':
                    localStorage.setItem('hash', val)
                    break
                case 'otl':
                    otl = val

                    setTimeout(() => {
                        window.history.pushState({}, '', '/')
                    }, 100)

                    break
                case 'lang':
                    langSet = true

                    if (langs[val] !== undefined) {
                        this.commit(LANG, val)
                    }
                    break
            }

            if (['bitreth'].indexOf(BRAND) > -1){
                this.commit(LANG, LANG_DEFAULT)
            }
            this.commit(MODAL_MENU, JSON.parse(localStorage.getItem('modalMenu')))
        }

        let toks = window.location.pathname.split('/')

        if (otl) {
            RestService.get('/auth/otl', {
                hash: otl
            })
                .then(res => {
                    this.commit(USER, res)
                    if(BRAND === 'mapledex'){
                        this.commit(MODAL_FULLSCREEN, {
                            page: 'deposit'
                        })
                    }
                })
        }

        switch (toks[1]) {
            case 'signup':
            case 'register':
                if(!SHOW_REGISTER_MODAL){
                    if (toks[2]) {
                        localStorage.setItem('referrer', toks[2])
                    }

                    if (!DISABLE_REGISTRATION) {
                        this.commit(MODAL, {
                            register: true
                        })
                        this.commit(BLUR, true)
                    }
                }
                break
            case 'login':
            case 'sign-in':
                this.commit(MODAL, {
                    login: true
                })
                break
            case 'deposit':
                if (otl) {
                    this.commit(MODAL, {
                        fullscreen: true
                    })
                    this.commit(MODAL_FULLSCREEN, {
                        page: 'deposit'
                    })
                }
                break
            case 'password_reset':
                this.commit(MODAL, {
                    newPassword: true,
                    login: false
                })
                break
            case 'deposit_success':
                this.commit(MODAL, {
                    depositSuccess: true,
                })
                break
            case 'deposit_cancel':
                this.commit(MODAL, {
                    depositSuccess: false,
                })
                break
            case 'deposit_fail':
                this.commit(MODAL, {
                    depositFail: true,
                })
                break
        }

        let favs = localStorage.getItem('symbolsFavorite')

        if (favs) {
            try {
                favs = JSON.parse(favs)
            } catch (e) {
                return
            }

            if (!Array.isArray(favs)) {
                return
            }

            favs.forEach(sym => {
                this.commit(SYMBOL_FAVORITE, {
                    symbol: sym,
                    action: 'add'
                })
            })
        }

        const updateAmounts = amts => {
            if (!amts) {
                this.commit(LAST_AMOUNTS, [100, 100, 100, 100, 100])
                return
            }

            try {
                amts = JSON.parse(amts)
            } catch (e) {
                this.commit(LAST_AMOUNTS, [100, 100, 100, 100, 100])
                return
            }

            if (!Array.isArray(amts)) {
                this.commit(LAST_AMOUNTS, [100, 100, 100, 100, 100])
                return
            }

            this.commit(LAST_AMOUNTS, amts)
        }

        updateAmounts(localStorage.getItem('lastAmounts'))

        let mute = localStorage.getItem('mute') === 'true'
        this.commit(MUTE, mute)

        if (!langSet) {
            let lang = localStorage.getItem('langActive')

            if (lang) {
                this.commit(LANG, lang)
            } else {
                lang = navigator.language || navigator.userLanguage

                if (!lang) {
                    this.commit(LANG, LANG_DEFAULT)
                } else {
                    lang = lang.split('-')[0]

                    this.commit(LANG, state.languages.indexOf(lang) === -1 ? LANG_DEFAULT : lang)
                }
            }
        }

        let ordersPaneInitialState = false
        if (localStorage.getItem('ordersPane') === null || localStorage.getItem('ordersPane') === 'open') {
            ordersPaneInitialState = true
        }
        this.commit(ORDERS_PANE, ordersPaneInitialState)

        RestService.get('/variables', {
            variables: VARIABLES
        })
            .then(variables => {
                for (let name in variables) {
                    this.commit(VARIABLE, {
                        name,
                        value: variables[name]
                    })
                }
            })

        RestService.get('/currencies',)
            .then(curr => {
                this.commit(CURRENCIES, curr)
            })

        window.onbeforeunload = () => {
            if (DROP_SESSION) {
                localStorage.removeItem('token')
            }
        }
    },

    [PLAY_CLICK]() {
        if (state.mute || isMobile()) {
            return
        }

        sounds.click.play()
            .then(() => {})
            .catch( () => {})

    },

    [PLAY_ORDER_OPEN]() {
        if (state.mute || isMobile()) {
            return
        }

        sounds.orderOpen.play()
            .then(() => {})
            .catch( () => {})
    },

    [PLAY_ORDER_WIN]() {
        if (state.mute || isMobile()) {
            return
        }

        sounds.orderWin.play()
            .then(() => {})
            .catch( () => {})
    },

    [PLAY_ORDER_LOSE]() {
        if (state.mute || isMobile()) {
            return
        }

        sounds.orderLose.play()
            .then(() => {})
            .catch( () => {})
    },

    [CFD_ORDER_OPEN_REQUEST](context, order) {
        let amounts = state.lastAmounts

        amounts.push(parseFloat(order.easy_amount))

        this.commit(LAST_AMOUNTS, amounts)
    }
}

const mutations = {
    [VARIABLE](context, data) {
        Vue.set(state.variables, data.name, data.value)
    },

    [CURRENCIES](context, data) {
        state.currencies = data
    },

    [SYMBOL_FAVORITE](context, sym) {
        let index
        switch (sym.action) {
            case 'add':
                if (state.symbolsFavorite.indexOf(sym.symbol) === -1) {
                    state.symbolsFavorite.push(sym.symbol)

                    localStorage.setItem('symbolsFavorite', JSON.stringify(state.symbolsFavorite))
                }
                break
            case 'remove':
                index = state.symbolsFavorite.indexOf(sym.symbol)
                if (index === -1) {
                    return
                }

                state.symbolsFavorite.splice(index, 1)

                localStorage.setItem('symbolsFavorite', JSON.stringify(state.symbolsFavorite))

                Vue.set(state, 'symbolsFavorite', state.symbolsFavorite)
                break

        }
    },

    [CHECK_NOTIFICATION_PROMISE]() {
        try {
            if (Notification.permission !== 'denied') {
                Notification.requestPermission().then()
            }
        } catch (e) {
            return false
        }

        return true
    },

    [SHOW_WEB_NOTIFICATION](context, val) {
        if (document.visibilityState === 'visible'){
            return
        }

        if ((window.Notification || window.webkitNotifications /* old WebKit Browsers */ || navigator.mozNotification /* Firefox for Android and Firefox OS */) && Notification.permission !== 'denied') {
              try {
                  const notification = new Notification(val.title, val)
                  notification.onclick = (event) => {
                      event.preventDefault()
                      window.open(window.location.origin, '_blank')
                  }
              }
              catch (e){
                  console.log(e)
              }
        }
    },

    [NOTIFICATION](context, val) {
        let id = state.notificationId++,
            notification = {
                type: 'success',
                text: val,
                id
            }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 4000)
    },

    [NOTIFICATION_ERROR](context, val) {
        let id = state.notificationId++,
            notification = {
                type: 'error',
                text: val,
                id
            }

        for (let n in state.notifications) {
            if (state.notifications[n].text === notification.text) {
                return
            }
        }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 4000)
    },

    [NOTIFICATION_ORDER](context, data) {
        let id = state.notificationId++,
            notification = {
                type: 'order',
                text: '',
                data,
                id
            }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 6000)
    },

    [NOTIFICATION_ORDER_OPEN](context, data) {
        let id = state.notificationId++,
            notification = {
                type: 'order_open',
                text: '',
                data,
                id
            }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 6000)
    },

    [NOTIFICATION_SUPPORT](context, val) {
        let id = state.notificationId++,
            notification = {
                type: 'support',
                text: val,
                id
            }

        state.notifications.push(notification)
        setTimeout(() => {
            this.commit(NOTIFICATION_REMOVE, id)
        }, 6000)
    },

    [NOTIFICATION_TRANSACTION](context, data) {
        let id = state.notificationId++,
            notification = {
                type: 'transaction',
                text: '',
                data,
                id
            }

        state.notifications.push(notification)
    },

    [NOTIFICATION_REMOVE](context, id) {
        for (let n in state.notifications) {
            if (state.notifications[n].id === id) {
                state.notifications.splice(n, 1)
                break
            }
        }

        Vue.set(state, 'notifications', state.notifications)
    },

    [NOTIFICATION_MARGIN](){
        let id = state.notificationId++,
            notification = {
                title: 'Margin Call',
                text: 'Please make a deposit to meet margin requirements',
                type: 'margin_call',
                id
            }

        state.notifications.push(notification)
    },

    [MODAL](context, data) {
        for (let name in data) {
            if (state.modals[name] !== undefined) {
                state.modals[name] = data[name]
            }
        }
    },

    [MODAL_MENU](context, data) {
        for (let name in data) {
            if (state.modalMenu[name] !== undefined) {
                state.modalMenu[name] = data[name]
            }
        }
        localStorage.setItem('modalMenu', JSON.stringify(state.modalMenu))
    },

    [MODAL_SYMBOLS](context, data) {
        for (let name in data) {
            if (state.modalSymbols[name] !== undefined) {
                state.modalSymbols[name] = data[name]
            }
        }
    },

    [MODAL_EXPIRATIONS](context, data) {
        for (let name in data) {
            if (state.modalExpirations[name] !== undefined) {
                state.modalExpirations[name] = data[name]
            }
        }
    },

    [MODAL_AMOUNTS](context, data) {
        for (let name in data) {
            if (state.modalAmounts[name] !== undefined) {
                state.modalAmounts[name] = data[name]
            }
        }
    },

    [MODAL_AUTOCLOSE](context, data) {
        for (let name in data) {
            if (state.modalAutoClose[name] !== undefined) {
                state.modalAutoClose[name] = data[name]
            }
        }
    },

    [MODAL_PENDING](context, data) {
        for (let name in data) {
            if (state.modalPending[name] !== undefined) {
                state.modalPending[name] = data[name]
            }
        }
    },

    [MODAL_CLOSE_POSITION](context, data) {
        for (let name in data) {
            if (state.modalClosePosition[name] !== undefined) {
                state.modalClosePosition[name] = data[name]
            }
        }
    },

    [MODAL_FULLSCREEN](context, data) {
        for (let name in data) {
            if (state.modalFullscreen[name] !== undefined) {
                state.modalFullscreen[name] = data[name]
            }
        }
    },

    [MODAL_ASSETS_INFO](context, data) {
        for (let name in data) {
            if (state.modalAssetsInfo[name] !== undefined) {
                state.modalAssetsInfo[name] = data[name]
            }
        }
    },

    [BLUR](context, val) {
        state.blur = val
    },

    [MUTE](context, val) {
        state.mute = val

        localStorage.setItem('mute', val)
    },

    [USER]() {
        state.blur = false
    },

    [SYMBOL]() {
        if (!state.gridLayout) {
            let grid = MULTIGRID === '1' ? localStorage.getItem('gridLayout') : '1-1'

            let charts = localStorage.getItem('charts'),
                invalid = false

            try {
                charts = JSON.parse(charts)
            } catch (e) {
                invalid = true
            }

            if (!invalid && charts) {
                state.charts = charts
            }

            let gridCharts = localStorage.getItem('gridCharts')

            try {
                gridCharts = JSON.parse(gridCharts)
            } catch (e) {
                invalid = true
            }

            if (!invalid && gridCharts) {
                state.gridCharts = gridCharts
            }

            this.commit(GRID_LAYOUT, grid)

            state.chartActive = localStorage.getItem('chartActive') || undefined

            RestService.err(err => {
                this.commit(NOTIFICATION_ERROR, err)
            })
        }
    },

    [GRID_LAYOUT](context, val) {
        state.gridLayout = val

        localStorage.setItem('gridLayout', val)

        let activeList = []

        for (let n in state.gridCharts) {
            if (state.gridCharts[n] !== undefined) {
                activeList.push(state.gridCharts[n])
            }
        }

        let toks = val.split('-'),
            i = 0,
            visibleCnt = 0

        while (i < toks[0]) {
            if (state.gridCharts[i] === undefined) {
                for (let n in state.charts) {
                    let id = state.charts[n].id
                    if (activeList.indexOf(id) !== -1) {
                        continue
                    }

                    state.gridCharts[i] = id
                    activeList.push(id)
                    visibleCnt++
                    break
                }
            } else {
                visibleCnt++
            }
            i++
        }

        while (i < 4) {
            state.gridCharts[i] = undefined
            i++
        }

        while (visibleCnt < toks[0]) {
            let chart = {
                id: randomId(),
                symbol: SYMBOLS_ACTIVE[visibleCnt].symbol,
                frame: SYMBOLS_ACTIVE[visibleCnt].frame
            }

            this.commit(CHART, chart)

            visibleCnt++
        }

        localStorage.setItem('gridCharts', JSON.stringify(state.gridCharts))
    },

    [CHART](context, data) {
        Vue.set(state.charts, data.id, data)

        let toks = state.gridLayout.split('-'),
            i = 0

        while (i < toks[0]) {
            if (state.gridCharts[i] === undefined) {
                state.gridCharts[i] = data.id
                break
            }
            i++
        }

        if (state.chartActive === undefined) {
            this.commit(CHART_ACTIVE, data.id)
        }

        localStorage.setItem('charts', JSON.stringify(state.charts))
        localStorage.setItem('gridCharts', JSON.stringify(state.gridCharts))
    },

    [SYMBOL_SHOW](context, data) {
        let chart = {
            id: randomId(),
            symbol: data.symbol,
            frame: data.frame
        }

        this.commit(CHART, chart)

        if (data.gridId !== undefined) {
            state.gridCharts[data.gridId] = chart.id
        }

        this.commit(MODAL, {
            loader: false
        })

        this.commit(CHART_ACTIVE, chart.id)
    },

    [SYMBOL_HIDE](context, id) {
        Vue.delete(state.charts, id)

        if (state.chartActive === id) {
            for (let n in state.charts) {
                this.commit(CHART_ACTIVE, state.charts[n].id)
                break
            }
        }

        localStorage.setItem('charts', JSON.stringify(state.charts))
    },

    [SYMBOL_ACTIVE_UPDATE](context, data) {
        if (!data.id || state.charts[data.id] === undefined) {
            return
        }

        Vue.set(state.charts, data.id, data)
        Vue.set(state.modalAutoClose, 'tp', '')
        Vue.set(state.modalAutoClose, 'sl', '')

        localStorage.setItem('charts', JSON.stringify(state.charts))
    },

    [CHART_ACTIVE](context, chart) {
        let prev = state.chartActive

        state.chartActive = chart

        for (let n in state.gridCharts) {
            if (state.gridCharts[n] === prev) {
                let wasHidden = true
                for (let m in state.gridCharts) {
                    if (state.gridCharts[m] === chart) {
                        wasHidden = false
                        break
                    }
                }

                if (wasHidden) {
                    state.gridCharts[n] = chart
                }
            }
        }

        localStorage.setItem('gridCharts', JSON.stringify(state.gridCharts))
        localStorage.setItem('chartActive', state.chartActive)
    },

    [LAST_AMOUNTS](context, amounts) {
        amounts = amounts.slice(-5)

        state.lastAmounts = amounts

        localStorage.setItem('lastAmounts', JSON.stringify(amounts))
    },

    [LANG](context, val) {
        state.langActive = val

        localStorage.setItem('langActive', val)
    },

    [LOGOUT]() {
        this.commit(BLUR, true)
        for (let n in state.modals) {
            state.modals[n] = ['login', 'menu', 'sideMenu'].indexOf(n) !== -1
        }
    },

    [ORDERS_PANE](context, val) {
        state.ordersPane = val

        if (val) {
            localStorage.setItem('ordersPane', 'open')
        } else {
            localStorage.setItem('ordersPane', 'closed')
        }
    },

    [HISTORY_TABLE_IS_ACTIVE](context, data) {
        state.historyTableIsActive = data
    },

    [TIME_SHIFT](context, val) {
        let token

        if (state.timeShift === null) {
            state.timeShift = val

            if (state.authToken) {
                window.history.pushState({}, '', '/')

                try {
                    if (dateCorr().getTime() - jwt.decode(state.authToken).created * 1000 < 1000 * 60 * 30) {
                        token = state.authToken
                    } else {
                        this.commit(NOTIFICATION_ERROR, 'INVALID_ACCOUNT')
                    }
                } catch (e) {
                    this.commit(NOTIFICATION_ERROR, 'INVALID_ACCOUNT')
                }

                if (!token) {
                    token = localStorage.getItem('token')
                }

                if (token && token !== 'undefined' && token !== 'null') {
                    this.commit(TOKEN, token)
                }
                this.dispatch(APP_READY)
            } else {
                token = localStorage.getItem('token')

                if (token && token !== 'undefined' && token !== 'null') {
                    this.commit(TOKEN, token)
                }

                this.dispatch(APP_READY)
            }
        } else {
            state.timeShift = val
        }
    },

    [MODAL_WITHDRAWAL_CANCEL](context, id) {
        state.modalWithdrawalCancel.id = id
    },

    [SET_TERMS_STATE](_, data) {
        state.termsIsAccept = data
        localStorage.setItem('termsIsAccept', data)
    },

    [SET_THEME_COLORS](context, themeColors) {
        state.themeColors = themeColors
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
