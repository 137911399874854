import store from '@/store'

export const parseQuote = quote => {
    quote = quote.split(',')

    return {
        symbol: quote[0],
        group: quote[1],
        bid: parseFloat(quote[2]),
        ask: parseFloat(quote[3]),
        volume: parseFloat(quote[4]),
        trend: parseFloat(quote[5]),
        change: parseFloat(quote[6]),
        changePct: parseFloat(quote[7])
    }
}

export const currencySign = currency => {
    if (/[^a-zA-Z]/.test(currency)) {
        return '$';
    }

    switch (currency) {
        case 'USD': return '$'
        case 'EUR': return '€'
        case 'GBP': return '£'
        case 'JPY': return '¥'
        case 'RUB': return '₽'
        case 'ILS': return '₪'
        case 'AUD': return '₳'
        case 'THB': return '฿'
        case 'KRW': return '₩'
        case 'BTC': return '₿'
        case 'INR': return '₹'
        case 'KZT': return '₸'
        default: return currency+' '
    }
}

export const orderTypeString = type => {
    switch (type) {
        case 0: return 'Buy'
        case 1: return 'Sell'
        case 2: return 'Buy Limit'
        case 3: return 'Sell Limit'
        case 4: return 'Buy Stop'
        case 5: return 'Sell Stop'
        default: return ''
    }
}

export const timeToString = time => {
    let stamp = time,
        date = new Date(stamp)

    return ('00' + date.getUTCDate()).slice(-2) + '.' +
        ('00' + (date.getUTCMonth()+1)).slice(-2) + '.' +
        date.getUTCFullYear() + ' ' +
        ('00' + date.getUTCHours()).slice(-2) + ':' +
        ('00' + date.getUTCMinutes()).slice(-2) + ':' +
        ('00' + date.getUTCSeconds()).slice(-2)
}

export const timeToPeriod = time => {
    if (time < 90) {
        return time+' sec'
    }

    if (time < 90 * 60) {
        return Math.floor(time / 60)+' min'
    }

    if (time < 3600 * 96) {
        return Math.floor(time / 3600)+' hr'
    }

    return Math.floor(time / 86400)+' days'
}

export const checkSession = (session, time) => {
    let date = time ? new Date(time) : new Date(),
        d = date.getUTCDay(),
        h = date.getUTCHours(),
        m = date.getUTCMinutes(),
        s = session[d][h],
        prev = h > 0 ? session[d][h-1] : (d > 0 ? session[d-1][23] : session[6][23])

    return (s === 0 || s === 1) ? !!s : (s * 60 < m ? !prev : !!prev)
}

export const requestFullScreen = (element) => {
    if (!document.fullscreenElement) {
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) {
            requestMethod.call(element);
        }
    } else {
        document.exitFullscreen()
    }
}

export const countriesList = () => {
    let obj = {
        AF: 'Afghanistan',
        AX: 'Aland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua And Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia And Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island & Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle Of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KR: 'Korea South',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        BL: 'St Barthelemy',
        SH: 'St Helena',
        KN: 'St Kitts And Nevis',
        LC: 'St Lucia',
        MF: 'St Martin',
        PM: 'St Pierre And Miquelon',
        VC: 'St Vincent And Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome And Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia And Sandwich Isl.',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard And Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad And Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks And Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis And Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
    }

    return obj
}

export const countryToCode = name => {
    let obj = {
        AF: 'Afghanistan',
        AX: 'Aland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua And Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia And Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island & Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle Of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KR: 'Korea South',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        BL: 'St Barthelemy',
        SH: 'St Helena',
        KN: 'St Kitts And Nevis',
        LC: 'St Lucia',
        MF: 'St Martin',
        PM: 'St Pierre And Miquelon',
        VC: 'St Vincent And Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome And Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia And Sandwich Isl.',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard And Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad And Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks And Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis And Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
    }

    for(let n in obj) {
        if (obj[n] === name) {
            return n
        }
    }

    return ''
}

export const isMobile = () => {
    // return true
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

export const randomId = () => {
    return 'r_'+Math.floor(Math.random()*999999)
}

let storage = {},
    lS

try {
    window.localStorage.getItem('test')
} catch (e) {
    lS = {
        setItem(key, val) {
            storage[key] = val
        },

        getItem(key) {
            return storage[key] || null
        },

        removeItem(key) {
            delete storage[key]
        }
    }
}

lS = lS || window.localStorage

export const localStorage = lS

export const dateCorr = () => {
    return new Date(Date.now() + store.getters.timeShift)
}

export const showPass = element => element && element.type ? element.type = "text" : ''
export const hidePass = element => element && element.type ? element.type = "password" : ''

export const fullCountriesList = () => {
    let obj = {
        AF: { name: 'Afghanistan', code: '+93', mask: 'XXXXXXXXXXXX' },
        AX: { name: 'Aland Islands', code: '+358', mask: 'XXXXXXXXXXXX' },
        AL: { name: 'Albania', code: '+355', mask: 'XXXXXXXXXXXX' },
        DZ: { name: 'Algeria', code: '+213', mask: 'XXXXXXXXXXXX' },
        AS: { name: 'American Samoa', code: '+1684', mask: 'XXXXXXXXXXXX' },
        AD: { name: 'Andorra', code: '+376', mask: 'XXXXXXXXXXXX' },
        AO: { name: 'Angola', code: '+244', mask: 'XXXXXXXXXXXX' },
        AI: { name: 'Anguilla', code: '+1264', mask: 'XXXXXXXXXXX' },
        AQ: { name: 'Antarctica', code: '+672', mask: 'XXXXXXXXXXX' },
        AG: { name: 'Antigua And Barbuda', code: '+1268', mask: 'XXXXXXXXXXX' },
        AR: { name: 'Argentina', code: '+54', mask: 'XXXXXXXXXXXX' },
        AM: { name: 'Armenia', code: '+374', mask: 'XXXXXXXXXXXX' },
        AW: { name: 'Aruba', code: '+297', mask: 'XXXXXXXXXXX' },
        AU: {name: 'Australia', code: '+61', mask: 'XXXXXXXXXXXXX', min: 9, max: 10},
        AT: { name: 'Austria', code: '+43', mask: 'XXXXXXXXXXXX' },
        AZ: { name: 'Azerbaijan', code: '+994', mask: 'XXXXXXXXXXXX' },
        BS: { name: 'Bahamas', code: '+1242', mask: 'XXXXXXXXXXXX' },
        BH: { name: 'Bahrain', code: '+973', mask: 'XXXXXXXXXXXX' },
        BD: { name: 'Bangladesh', code: '+880', mask: 'XXXXXXXXXXXX' },
        BB: { name: 'Barbados', code: '+1246', mask: 'XXXXXXXXXXX' },
        BY: { name: 'Belarus', code: '+375', mask: 'XXXXXXXXXXXX' },
        BE: { name: 'Belgium', code: '+32', mask: 'XXXXXXXXXXXX' },
        BZ: { name: 'Belize', code: '+501', mask: 'XXXXXXXXXXX' },
        BJ: { name: 'Benin', code: '+229', mask: 'XXXXXXXXXXXX' },
        BM: { name: 'Bermuda', code: '+1441', mask: 'XXXXXXXXXXXX' },
        BT: { name: 'Bhutan', code: '+975', mask: 'XXXXXXXXXXX' },
        BO: { name: 'Bolivia', code: '+591', mask: 'XXXXXXXXXXXX' },
        BA: { name: 'Bosnia And Herzegovina', code: '+387', mask: 'XXXXXXXXXXXX' },
        BW: { name: 'Botswana', code: '+267', mask: 'XXXXXXXXXXX' },
        BV: { name: 'Bouvet Island', code: '', mask: '' }, // No specific code and mask
        BR: { name: 'Brazil', code: '+55', mask: 'XXXXXXXXXXXXX' },
        IO: { name: 'British Indian Ocean Territory', code: '', mask: '' }, // No specific code and mask
        BN: { name: 'Brunei Darussalam', code: '+673', mask: 'XXXXXXXXXXX' },
        BG: { name: 'Bulgaria', code: '+359', mask: 'XXXXXXXXXXXX' },
        BF: { name: 'Burkina Faso', code: '+226', mask: 'XXXXXXXXXXXX' },
        BI: { name: 'Burundi', code: '+257', mask: 'XXXXXXXXXXXX' },
        KH: { name: 'Cambodia', code: '+855', mask: 'XXXXXXXXXXXX' },
        CM: { name: 'Cameroon', code: '+237', mask: 'XXXXXXXXXXXX' },
        CA: { name: 'Canada', code: '+1', mask: 'XXXXXXXXXXXX' },
        CV: { name: 'Cape Verde', code: '+238', mask: 'XXXXXXXXXXX' },
        KY: { name: 'Cayman Islands', code: '+1345', mask: 'XXXXXXXXXXX' },
        CF: { name: 'Central African Republic', code: '+236', mask: 'XXXXXXXXXXXX' },
        TD: { name: 'Chad', code: '+235', mask: 'XXXXXXXXXXXX' },
        CL: { name: 'Chile', code: '+56', mask: 'XXXXXXXXXXXX' },
        CN: { name: 'China', code: '+86', mask: 'XXXXXXXXXXXXX' },
        CX: { name: 'Christmas Island', code: '+61', mask: 'XXXXXXXXXXX' },
        CC: { name: 'Cocos (Keeling) Islands', code: '+61', mask: 'XXXXXXXXXXX' },
        CO: { name: 'Colombia', code: '+57', mask: 'XXXXXXXXXXXX' },
        KM: { name: 'Comoros', code: '+269', mask: 'XXXXXXXXXXXX' },
        CG: { name: 'Congo', code: '+242', mask: 'XXXXXXXXXXXX' },
        CD: { name: 'Congo, Democratic Republic', code: '+243', mask: 'XXXXXXXXXXXX' },
        CK: { name: 'Cook Islands', code: '+682', mask: 'XXXXXXXXXXX' },
        CR: { name: 'Costa Rica', code: '+506', mask: 'XXXXXXXXXXX' },
        CI: { name: "Cote D'Ivoire", code: '+225', mask: 'XXXXXXXXXXXX' },
        HR: { name: 'Croatia', code: '+385', mask: 'XXXXXXXXXXXX' },
        CU: { name: 'Cuba', code: '+53', mask: 'XXXXXXXXXXX' },
        CY: { name: 'Cyprus', code: '+357', mask: 'XXXXXXXXXXXX' },
        CZ: { name: 'Czech Republic', code: '+420', mask: 'XXXXXXXXXXXXX' },
        DK: { name: 'Denmark', code: '+45', mask: 'XXXXXXXXXXX' },
        DJ: { name: 'Djibouti', code: '+253', mask: 'XXXXXXXXXXXX' },
        DM: { name: 'Dominica', code: '+1767', mask: 'XXXXXXXXXXXX' },
        DO: { name: 'Dominican Republic', code: '+1849', mask: 'XXXXXXXXXXX' },
        EC: { name: 'Ecuador', code: '+593', mask: 'XXXXXXXXXXXX' },
        EG: { name: 'Egypt', code: '+20', mask: 'XXXXXXXXXXXX' },
        SV: { name: 'El Salvador', code: '+503', mask: 'XXXXXXXXXXXX' },
        GQ: { name: 'Equatorial Guinea', code: '+240', mask: 'XXXXXXXXXXXX' },
        ER: { name: 'Eritrea', code: '+291', mask: 'XXXXXXXXXXXX' },
        EE: { name: 'Estonia', code: '+372', mask: 'XXXXXXXXXXXX' },
        ET: { name: 'Ethiopia', code: '+251', mask: 'XXXXXXXXXXXX' },
        FK: { name: 'Falkland Islands (Malvinas)', code: '+500', mask: 'XXXXXXXXXXX' },
        FO: { name: 'Faroe Islands', code: '+298', mask: 'XXXXXXXX' },
        FJ: { name: 'Fiji', code: '+679', mask: 'XXXXXXXX' },
        FI: { name: 'Finland', code: '+358', mask: 'XXXXXXXXXXXX' },
        FR: { name: 'France', code: '+33', mask: 'XXXXXXXXXX' },
        GF: { name: 'French Guiana', code: '+594', mask: 'XXXXXXXXXXX' },
        PF: { name: 'French Polynesia', code: '+689', mask: 'XXXXXXXXXX' },
        TF: { name: 'French Southern Territories', code: '', mask: '' }, // No specific code and mask
        GA: { name: 'Gabon', code: '+241', mask: 'XXXXXXXXXXXX' },
        GM: { name: 'Gambia', code: '+220', mask: 'XXXXXXXX' },
        GE: { name: 'Georgia', code: '+995', mask: 'XXXXXXXXXXXXX' },
        DE: { name: 'Germany', code: '+49', mask: 'XXXXXXXXXXX' },
        GH: { name: 'Ghana', code: '+233', mask: 'XXXXXXXXXXXX' },
        GI: { name: 'Gibraltar', code: '+350', mask: 'XXXXXXXXX' },
        GR: { name: 'Greece', code: '+30', mask: 'XXXXXXXXXXXXX' },
        GL: { name: 'Greenland', code: '+299', mask: 'XXXXXXXX' },
        GD: { name: 'Grenada', code: '+1473', mask: 'XXXXXXXX' },
        GP: { name: 'Guadeloupe', code: '+590', mask: 'XXXXXXXXXX' },
        GU: { name: 'Guam', code: '+1671', mask: 'XXXXXXXXXXX' },
        GT: { name: 'Guatemala', code: '+502', mask: 'XXXXXXXXXXXX' },
        GG: { name: 'Guernsey', code: '+44', mask: 'XXXXXXXXXXXXX' },
        GN: { name: 'Guinea', code: '+224', mask: 'XXXXXXXXXXXX' },
        GW: { name: 'Guinea-Bissau', code: '+245', mask: 'XXXXXXXXXXXX' },
        GY: { name: 'Guyana', code: '+592', mask: 'XXXXXXXX' },
        HT: { name: 'Haiti', code: '+509', mask: 'XXXXXXXXXXXX' },
        HM: { name: 'Heard Island & Mcdonald Islands', code: '', mask: '' }, // No specific code and mask
        VA: { name: 'Holy See (Vatican City State)', code: '+379', mask: 'XXXXXXXXXXXXX' },
        HN: { name: 'Honduras', code: '+504', mask: 'XXXXXXXXXXXX' },
        HK: { name: 'Hong Kong', code: '+852', mask: 'XXXXXXXXXXXX' },
        HU: { name: 'Hungary', code: '+36', mask: 'XXXXXXXXXXXX' },
        IS: { name: 'Iceland', code: '+354', mask: 'XXXXXXXXXXX' },
        IN: { name: 'India', code: '+91', mask: 'XXXXXXXXXXXXX' },
        ID: { name: 'Indonesia', code: '+62', mask: 'XXXXXXXXXXXX' },
        IR: { name: 'Iran, Islamic Republic Of', code: '+98', mask: 'XXXXXXXXXXXX' },
        IQ: { name: 'Iraq', code: '+964', mask: 'XXXXXXXXXXXX' },
        IE: { name: 'Ireland', code: '+353', mask: 'XXXXXXXXXXX' },
        IM: { name: 'Isle Of Man', code: '+44', mask: 'XXXXXXXXXXXX' },
        IL: { name: 'Israel', code: '+972', mask: 'XXXXXXXXXXX' },
        IT: { name: 'Italy', code: '+39', mask: 'XXXXXXXXXXXXX' },
        JM: { name: 'Jamaica', code: '+1876', mask: 'XXXXXXXXXXXX' },
        JP: { name: 'Japan', code: '+81', mask: 'XXXXXXXXXXXXXX' },
        JE: { name: 'Jersey', code: '+44', mask: 'XXXXXXXXXXXX' },
        JO: { name: 'Jordan', code: '+962', mask: 'XXXXXXXXXXXX' },
        KZ: { name: 'Kazakhstan', code: '+7', mask: 'XXXXXXXXXXXX' },
        KE: { name: 'Kenya', code: '+254', mask: 'XXXXXXXXXXXX' },
        KI: { name: 'Kiribati', code: '+686', mask: 'XXXXXXXX' },
        KR: { name: 'Korea', code: '+82', mask: 'XXXXXXXXXXX' },
        KW: { name: 'Kuwait', code: '+965', mask: 'XXXXXXXXXXXX' },
        KG: { name: 'Kyrgyzstan', code: '+996', mask: 'XXXXXXXXXXXXX' },
        LA: { name: 'Lao People\'s Democratic Republic', code: '+856', mask: 'XXXXXXXXXXXX' },
        LV: { name: 'Latvia', code: '+371', mask: 'XXXXXXXXXXXX' },
        LB: { name: 'Lebanon', code: '+961', mask: 'XXXXXXXXX' },
        LS: { name: 'Lesotho', code: '+266', mask: 'XXXXXXXXXXXX' },
        LR: { name: 'Liberia', code: '+231', mask: 'XXXXXXXXXXXX' },
        LY: { name: 'Libyan Arab Jamahiriya', code: '+218', mask: 'XXXXXXXXXXXXX' },
        LI: { name: 'Liechtenstein', code: '+423', mask: 'XXXXXXXXXXXX' },
        LT: { name: 'Lithuania', code: '+370', mask: 'XXXXXXXXXXX' },
        LU: { name: 'Luxembourg', code: '+352', mask: 'XXXXXXXXXXX' },
        MO: { name: 'Macao', code: '+853', mask: 'XXXXXXXXXXXX' },
        MK: { name: 'Macedonia, The Former Yugoslav Republic Of', code: '+389', mask: 'XXXXXXXXXXXX' },
        MG: { name: 'Madagascar', code: '+261', mask: 'XXXXXXXXXXX' },
        MW: { name: 'Malawi', code: '+265', mask: 'XXXXXXXXXXXX' },
        MY: { name: 'Malaysia', code: '+60', mask: 'XXXXXXXXXXXX' },
        MV: { name: 'Maldives', code: '+960', mask: 'XXXXXXXX' },
        ML: { name: 'Mali', code: '+223', mask: 'XXXXXXXXXX' },
        MT: { name: 'Malta', code: '+356', mask: 'XXXXXXXXXXXX' },
        MH: { name: 'Marshall Islands', code: '+692', mask: 'XXXXXXXX' },
        MQ: { name: 'Martinique', code: '+596', mask: 'XXXXXXXXXX' },
        MR: { name: 'Mauritania', code: '+222', mask: 'XXXXXXXXXX' },
        MU: { name: 'Mauritius', code: '+230', mask: 'XXXXXXXXXXXX' },
        YT: { name: 'Mayotte', code: '+262', mask: 'XXXXXXXXXXXX' },
        MX: { name: 'Mexico', code: '+52', mask: 'XXXXXXXXXXXXXX' },
        FM: { name: 'Micronesia, Federated States Of', code: '+691', mask: 'XXXXXXXX' },
        MD: { name: 'Moldova', code: '+373', mask: 'XXXXXXXXXXXX' },
        MC: { name: 'Monaco', code: '+377', mask: 'XXXXXXXXXXXX' },
        MN: { name: 'Mongolia', code: '+976', mask: 'XXXXXXXXXXXX' },
        MS: { name: 'Montserrat', code: '+1664', mask: 'XXXXXXXXXXX' },
        MA: { name: 'Morocco', code: '+212', mask: 'XXXXXXXXXXXX' },
        MZ: { name: 'Mozambique', code: '+258', mask: 'XXXXXXXXXXXXX' },
        MM: { name: 'Myanmar', code: '+95', mask: 'XXXXXXXXXXX' },
        NA: { name: 'Namibia', code: '+264', mask: 'XXXXXXXXXX' },
        NR: { name: 'Nauru', code: '+674', mask: 'XXXXXXXX' },
        NP: { name: 'Nepal', code: '+977', mask: 'XXXXXXXXXXXX' },
        NL: { name: 'Netherlands', code: '+31', mask: 'XXXXXXXXXXXX' },
        AN: { name: 'Netherlands Antilles', code: '+599', mask: 'XXXXXXXXXXX' },
        NC: { name: 'New Caledonia', code: '+687', mask: 'XXXXXXXX' },
        NZ: { name: 'New Zealand', code: '+64', mask: 'XXXXXXXXXXXX' },
        NI: { name: 'Nicaragua', code: '+505', mask: 'XXXXXXXXXXXX' },
        NE: { name: 'Niger', code: '+227', mask: 'XXXXXXXXXX' },
        NG: { name: 'Nigeria', code: '+234', mask: 'XXXXXXXXXXX' },
        NU: { name: 'Niue', code: '+683', mask: 'XXXXXXXX' },
        NF: { name: 'Norfolk Island', code: '+672', mask: 'XXXXXXXX' },
        MP: { name: 'Northern Mariana Islands', code: '+1670', mask: 'XXXXXXXXXXX' },
        NO: { name: 'Norway', code: '+47', mask: 'XXXXXXXXX' },
        OM: { name: 'Oman', code: '+968', mask: 'XXXXXXXXXXXX' },
        PK: { name: 'Pakistan', code: '+92', mask: 'XXXXXXXXXXX' },
        PW: { name: 'Palau', code: '+680', mask: 'XXXXXXXX' },
        PS: { name: 'Palestinian Territory, Occupied', code: '+970', mask: 'XXXXXXXXXXXX' },
        PA: { name: 'Panama', code: '+507', mask: 'XXXXXXXXXXXX' },
        PG: { name: 'Papua New Guinea', code: '+675', mask: 'XXXXXXXXXXX' },
        PY: { name: 'Paraguay', code: '+595', mask: 'XXXXXXXXXXX' },
        PE: { name: 'Peru', code: '+51', mask: 'XXXXXXXXXXX' },
        PH: { name: 'Philippines', code: '+63', mask: 'XXXXXXXXXXXX' },
        PN: { name: 'Pitcairn', code: '+872', mask: 'XXXXXXXXXXX' },
        PL: { name: 'Poland', code: '+48', mask: 'XXXXXXXXXXXX' },
        PT: { name: 'Portugal', code: '+351', mask: 'XXXXXXXXXXXXX' },
        PR: { name: 'Puerto Rico', code: '+1939', mask: 'XXXXXXXXXXX' },
        QA: { name: 'Qatar', code: '+974', mask: 'XXXXXXXXXXXX' },
        RE: { name: 'Reunion', code: '+262', mask: 'XXXXXXXXXX' },
        RO: { name: 'Romania', code: '+40', mask: 'XXXXXXXXXXXXX' },
        RU: { name: 'Russian Federation', code: '+7', mask: 'XXXXXXXXXXXX' },
        RW: { name: 'Rwanda', code: '+250', mask: 'XXXXXXXXXX' },
        BL: { name: 'Saint Barthelemy', code: '+590', mask: 'XXXXXXXXXX' },
        SH: { name: 'Saint Helena', code: '+290', mask: 'XXXXXXXX' },
        KN: { name: 'Saint Kitts And Nevis', code: '+1869', mask: 'XXXXXXXXXXX' },
        LC: { name: 'Saint Lucia', code: '+1758', mask: 'XXXXXXXXXXX' },
        MF: { name: 'Saint Martin', code: '+590', mask: 'XXXXXXXXXX' },
        PM: { name: 'Saint Pierre And Miquelon', code: '+508', mask: 'XXXXXXXXXX' },
        VC: { name: 'Saint Vincent And Grenadines', code: '+1784', mask: 'XXXXXXXXXXX' },
        WS: { name: 'Samoa', code: '+685', mask: 'XXXXXXXX' },
        SM: { name: 'San Marino', code: '+378', mask: 'XXXXXXXXXXXX' },
        ST: { name: 'Sao Tome And Principe', code: '+239', mask: 'XXXXXXXXXX' },
        SA: { name: 'Saudi Arabia', code: '+966', mask: 'XXXXXXXXXXXX' },
        SN: { name: 'Senegal', code: '+221', mask: 'XXXXXXXXXXX' },
        RS: { name: 'Serbia', code: '+381', mask: 'XXXXXXXXXXXXX' },
        SC: { name: 'Seychelles', code: '+248', mask: 'XXXXXXXXXX' },
        SL: { name: 'Sierra Leone', code: '+232', mask: 'XXXXXXXXXX' },
        SG: { name: 'Singapore', code: '+65', mask: 'XXXXXXXXXXXX' },
        SK: { name: 'Slovakia', code: '+421', mask: 'XXXXXXXXXXXXX' },
        SI: { name: 'Slovenia', code: '+386', mask: 'XXXXXXXXXXXXX' },
        SB: { name: 'Solomon Islands', code: '+677', mask: 'XXXXXXXX' },
        SO: { name: 'Somalia', code: '+252', mask: 'XXXXXXXXXXX' },
        ZA: { name: 'South Africa', code: '+27', mask: 'XXXXXXXXXXXXX' },
        GS: { name: 'South Georgia And Sandwich Isl.', code: '', mask: '' }, // No specific code and mask
        ES: { name: 'Spain', code: '+34', mask: 'XXXXXXXXXXXXX' },
        LK: { name: 'Sri Lanka', code: '+94', mask: 'XXXXXXXXXXXXX' },
        SD: { name: 'Sudan', code: '+249', mask: 'XXXXXXXXXXXX' },
        SR: { name: 'Suriname', code: '+597', mask: 'XXXXXXXXXXX' },
        SJ: { name: 'Svalbard And Jan Mayen', code: '+47', mask: 'XXXXXXXXXXX' },
        SZ: { name: 'Swaziland', code: '+268', mask: 'XXXXXXXXXXXX' },
        SE: { name: 'Sweden', code: '+46', mask: 'XXXXXXXXXXX' },
        CH: { name: 'Switzerland', code: '+41', mask: 'XXXXXXXXXXX' },
        SY: { name: 'Syrian Arab Republic', code: '+963', mask: 'XXXXXXXXXXXXX' },
        TW: { name: 'Taiwan', code: '+886', mask: 'XXXXXXXXXXXXXX' },
        TJ: { name: 'Tajikistan', code: '+992', mask: 'XXXXXXXXXXXX' },
        TZ: { name: 'Tanzania', code: '+255', mask: 'XXXXXXXXXXXXX' },
        TH: { name: 'Thailand', code: '+66', mask: 'XXXXXXXXXXXXXX' },
        TL: { name: 'Timor-Leste', code: '+670', mask: 'XXXXXXXXXXXX' },
        TG: { name: 'Togo', code: '+228', mask: 'XXXXXXXXXXXX' },
        TK: { name: 'Tokelau', code: '+690', mask: 'XXXXXXX' },
        TO: { name: 'Tonga', code: '+676', mask: 'XXXXXXXX' },
        TT: { name: 'Trinidad And Tobago', code: '+1868', mask: 'XXXXXXXXXXX' },
        TN: { name: 'Tunisia', code: '+216', mask: 'XXXXXXXXXXXX' },
        TR: { name: 'Turkey', code: '+90', mask: 'XXXXXXXXXXXXXX' },
        TM: { name: 'Turkmenistan', code: '+993', mask: 'XXXXXXXXXXXX' },
        TC: { name: 'Turks And Caicos Islands', code: '+1649', mask: 'XXXXXXXXXXX' },
        TV: { name: 'Tuvalu', code: '+688', mask: 'XXXXXXXX' },
        UG: { name: 'Uganda', code: '+256', mask: 'XXXXXXXXXXXXX' },
        UA: { name: 'Ukraine', code: '+380', mask: 'XXXXXXXXXXXXX' },
        AE: { name: 'United Arab Emirates', code: '+971', mask: 'XXXXXXXXXXXXXX' },
        GB: { name: 'United Kingdom', code: '+44', mask: 'XXXXXXXXXXXXX' },
        US: { name: 'United States', code: '+1', mask: 'XXXXXXXXXXXX' },
        UM: { name: 'United States Outlying Islands', code: '', mask: '' }, // No specific code and mask
        UY: { name: 'Uruguay', code: '+598', mask: 'XXXXXXXXXXXXX' },
        UZ: { name: 'Uzbekistan', code: '+998', mask: 'XXXXXXXXXXXX' },
        VU: { name: 'Vanuatu', code: '+678', mask: 'XXXXXXXX' },
        VE: { name: 'Venezuela', code: '+58', mask: 'XXXXXXXXXXX' },
        VN: { name: 'Viet Nam', code: '+84', mask: 'XXXXXXXXXXX' },
        VG: { name: 'Virgin Islands, British', code: '+1284', mask: 'XXXXXXXXXXX' },
        VI: { name: 'Virgin Islands, U.S.', code: '+1340', mask: 'XXXXXXXXXXX' },
        WF: { name: 'Wallis And Futuna', code: '+681', mask: 'XXXXXXXX' },
        EH: { name: 'Western Sahara', code: '+212', mask: 'XXXXXXXXXXXX' },
        YE: { name: 'Yemen', code: '+967', mask: 'XXXXXXXXXXX' },
        ZM: { name: 'Zambia', code: '+260', mask: 'XXXXXXXXXXXXX' },
        ZW: { name: 'Zimbabwe', code: '+263', mask: 'XXXXXXXXXXXXX' }
    }

    for(let n in obj) {
        obj[n].countryKeyCode = n
    }

    return obj
}

export const customToFixed = (num, pluseSign = false, numsCountAfterZero = 1) => {
    if (num === undefined || num === null || isNaN(num) || num === 0) return 0;
    let sign = "";
    if (num < 0) sign = "-";

    if (pluseSign && !sign) sign = "+";

    let numString = num.toString();

    if (numString === +num.toFixed())
      return sign === numString[0] ? numString : sign + numString;

    if (num >= 0.01 || num <= -0.01)
      return sign === numString[0] ? num.toFixed(2) : sign + num.toFixed(2);

    if (numString.includes("e-")) {
      if (numString.split("e-")[1] > 8) return 0;
      let afterZeroNumbers = "";

      for (let i = numString.indexOf(".") + 1; i < numString.length; i++) {
        if (
          numString[i] === "e" ||
          afterZeroNumbers.length > numsCountAfterZero - 2 ||
          numString.indexOf(".") === -1
        ) {
          break;
        }

        afterZeroNumbers += numString[i];
      }

      return (
        sign +
        "0." +
        "0".repeat(Math.abs(numString).toString().split("e-")[1] - 1) +
        (numString[0] === "-" ? numString[1] : numString[0]) +
        afterZeroNumbers
      );
    }

    let indexNotZero;
    let afterZeroNumbers = "";

    for (let i = numString.indexOf(".") + 1; i < numString.length; i++) {
      if (numString[i] !== "0") {
        indexNotZero = i;
        break;
      }
    }

    for (let i = indexNotZero + 1; i < indexNotZero + numsCountAfterZero; i++) {
      if (!numString[i]) break;
      afterZeroNumbers += numString[i];
    }

    return (
      sign +
      "0." +
      "0".repeat(indexNotZero - (sign ? 3 : 2)) +
      numString[indexNotZero] +
      afterZeroNumbers
    );
  };
